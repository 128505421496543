import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import Login from '@/account/login/login.vue';

import '@/shared/config/dayjs';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    login: Login,

    'jhi-footer': JhiFooter,
  },
})
export default class App extends Vue {
  public toHome() {
    this.$router.push('/').catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err;
      }
      // 可以忽略 NavigationDuplicated 错误，或者进行其他处理
    });
  }
}
