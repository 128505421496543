import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd111',
    name: 'Sd111',
    component: () => import('@/sandentw/sd110/sd111.vue'),
  },
  {
    path: '/sd112',
    name: 'Sd112',
    component: () => import('@/sandentw/sd110/sd112.vue'),
  },
  {
    path: '/sd113',
    name: 'Sd113',
    component: () => import('@/sandentw/sd110/sd113.vue'),
  },
  {
    path: '/sd114',
    name: 'Sd114',
    component: () => import('@/sandentw/sd110/sd114.vue'),
  },
  {
    path: '/sd115',
    name: 'Sd115',
    component: () => import('@/sandentw/sd110/sd115.vue'),
  },
];
