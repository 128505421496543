import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd131',
    name: 'Sd131',
    component: () => import('@/sandentw/sd130/sd131.vue'),
  },
  {
    path: '/sd132',
    name: 'Sd132',
    component: () => import('@/sandentw/sd130/sd132.vue'),
  },
  {
    path: '/sd133/:login/edit',
    name: 'Sd133Edit',
    component: () => import('@/sandentw/sd130/sd133.vue'),
  },
];
