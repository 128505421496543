import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd121',
    name: 'Sd121',
    component: () => import('@/sandentw/sd120/sd121.vue'),
  },
  {
    path: '/sd122',
    name: 'Sd122',
    component: () => import('@/sandentw/sd120/sd122.vue'),
  },
  {
    path: '/sd123',
    name: 'Sd123',
    component: () => import('@/sandentw/sd120/sd123.vue'),
  },
  {
    path: '/sd124',
    name: 'Sd124',
    component: () => import('@/sandentw/sd120/sd124.vue'),
  },
];
