import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd161',
    name: 'Sd161',
    component: () => import('@/sandentw/sd160/sd161.vue'),
  },
  {
    path: '/sd1621',
    name: 'Sd1621',
    component: () => import('@/sandentw/sd160/sd1620/sd1621.vue'),
  },
  {
    path: '/sd1622',
    name: 'Sd1622',
    component: () => import('@/sandentw/sd160/sd1620/sd1622.vue'),
  },
  {
    path: '/sd1623',
    name: 'Sd1623',
    component: () => import('@/sandentw/sd160/sd1620/sd1623.vue'),
  },
  {
    path: '/sd1624',
    name: 'Sd1624',
    component: () => import('@/sandentw/sd160/sd1620/sd1624.vue'),
  },
  {
    path: '/sd1631',
    name: 'Sd1631',
    component: () => import('@/sandentw/sd160/sd1630/sd1631.vue'),
  },
  {
    path: '/sd1632',
    name: 'Sd1632',
    component: () => import('@/sandentw/sd160/sd1630/sd1632.vue'),
  },
  {
    path: '/sd1633',
    name: 'Sd1633',
    component: () => import('@/sandentw/sd160/sd1630/sd1633.vue'),
  },
  {
    path: '/sd1634',
    name: 'Sd1634',
    component: () => import('@/sandentw/sd160/sd1630/sd1634.vue'),
  },
  {
    path: '/sd1635',
    name: 'Sd1635',
    component: () => import('@/sandentw/sd160/sd1630/sd1635.vue'),
  },
  {
    path: '/sd1641',
    name: 'Sd1641',
    component: () => import('@/sandentw/sd160/sd1640/sd1641.vue'),
  },
  {
    path: '/sd1642/:id',
    name: 'Sd1642',
    component: () => import('@/sandentw/sd160/sd1640/sd1642.vue'),
  },
];
