import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd171',
    name: 'Sd171',
    component: () => import('@/sandentw/sd170/sd171.vue'),
  },
  {
    path: '/sd172/:cId',
    name: 'Sd172',
    component: () => import('@/sandentw/sd170/sd172.vue'),
  },
  {
    path: '/sd173/:cId/:pId',
    name: 'Sd173',
    component: () => import('@/sandentw/sd170/sd173.vue'),
  },
];
