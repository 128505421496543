import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd181',
    name: 'Sd181',
    component: () => import('@/sandentw/sd180/sd181.vue'),
  },
  {
    path: '/sd182',
    name: 'Sd182',
    component: () => import('@/sandentw/sd180/sd182.vue'),
  },
  {
    path: '/sd183',
    name: 'Sd183',
    component: () => import('@/sandentw/sd180/sd183.vue'),
  },
  {
    path: '/sd184/:sId',
    name: 'Sd184',
    component: () => import('@/sandentw/sd180/sd184.vue'),
  },
];
