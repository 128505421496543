import axios from 'axios';
import Component from 'vue-class-component';
import { Vue, Inject } from 'vue-property-decorator';
import AccountService from '@/account/account.service';

@Component
export default class Login extends Vue {
  @Inject('accountService')
  private accountService: () => AccountService;
  public login = null;
  public password = null;
  public captchaCode = null;
  public rememberMe: boolean = null;
  public captcha: ICaptcha = null;
  private timerId: number | null = null;
  public dismissSecs: number = 5;
  public dismissCountDown: number = 0;
  public apiCountDown: number = 1000;
  public loadingButton: boolean = false;

  created() {
    this.getCaptcha();
  }

  beforeDestroy() {
    // 在组件销毁前清除计时器
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }
  }

  public getCaptcha(): void {
    this.captcha = null;
    this.timerId = setTimeout(() => {
      axios
        .get('api/wcc201/captcha')
        .then(result => {
          this.captcha = result.data;
        })
        .catch(() => {
          console.log('error');
        });
    }, this.apiCountDown);
  }

  public countDownChanged(dismissCountDown): void {
    this.dismissCountDown = dismissCountDown;
  }

  public async doLogin() {
    const data = {
      username: this.login,
      password: this.password,
      rememberMe: this.rememberMe,
      captchaId: this.captcha.id,
      captchaCode: this.captchaCode,
    };
    this.loadingButton = true;
    try {
      const response = await axios.post('api/authenticate', data);
      const bearerToken = response.headers.authorization;
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        if (this.rememberMe) {
          localStorage.setItem('jhi-authenticationToken', jwt);
          sessionStorage.removeItem('jhi-authenticationToken');
        } else {
          sessionStorage.setItem('jhi-authenticationToken', jwt);
          localStorage.removeItem('jhi-authenticationToken');
        }
      }
      this.accountService().retrieveAccount();
      this.$router.push('/sd102');
    } catch (error) {
      this.getCaptcha();
      this.dismissCountDown = this.dismissSecs;
    }
    this.loadingButton = false;
  }
}
