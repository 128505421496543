import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Captcha = () => import('@/entities/captcha/captcha.vue');
// prettier-ignore
const CaptchaUpdate = () => import('@/entities/captcha/captcha-update.vue');
// prettier-ignore
const CaptchaDetails = () => import('@/entities/captcha/captcha-details.vue');
// prettier-ignore
const EcpayReq = () => import('@/entities/ecpay-req/ecpay-req.vue');
// prettier-ignore
const EcpayReqUpdate = () => import('@/entities/ecpay-req/ecpay-req-update.vue');
// prettier-ignore
const EcpayReqDetails = () => import('@/entities/ecpay-req/ecpay-req-details.vue');
// prettier-ignore
const EcpayResp = () => import('@/entities/ecpay-resp/ecpay-resp.vue');
// prettier-ignore
const EcpayRespUpdate = () => import('@/entities/ecpay-resp/ecpay-resp-update.vue');
// prettier-ignore
const EcpayRespDetails = () => import('@/entities/ecpay-resp/ecpay-resp-details.vue');
// prettier-ignore
const Param = () => import('@/entities/param/param.vue');
// prettier-ignore
const ParamUpdate = () => import('@/entities/param/param-update.vue');
// prettier-ignore
const ParamDetails = () => import('@/entities/param/param-details.vue');
// prettier-ignore
const LogAop = () => import('@/entities/log-aop/log-aop.vue');
// prettier-ignore
const LogAopUpdate = () => import('@/entities/log-aop/log-aop-update.vue');
// prettier-ignore
const LogAopDetails = () => import('@/entities/log-aop/log-aop-details.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const ProductImage = () => import('@/entities/product-image/product-image.vue');
// prettier-ignore
const ProductImageUpdate = () => import('@/entities/product-image/product-image-update.vue');
// prettier-ignore
const ProductImageDetails = () => import('@/entities/product-image/product-image-details.vue');
// prettier-ignore
const Status = () => import('@/entities/status/status.vue');
// prettier-ignore
const StatusUpdate = () => import('@/entities/status/status-update.vue');
// prettier-ignore
const StatusDetails = () => import('@/entities/status/status-details.vue');
// prettier-ignore
const Stock = () => import('@/entities/stock/stock.vue');
// prettier-ignore
const StockUpdate = () => import('@/entities/stock/stock-update.vue');
// prettier-ignore
const StockDetails = () => import('@/entities/stock/stock-details.vue');
// prettier-ignore
const Record = () => import('@/entities/record/record.vue');
// prettier-ignore
const RecordUpdate = () => import('@/entities/record/record-update.vue');
// prettier-ignore
const RecordDetails = () => import('@/entities/record/record-details.vue');
// prettier-ignore
const Prod = () => import('@/entities/prod/prod.vue');
// prettier-ignore
const ProdUpdate = () => import('@/entities/prod/prod-update.vue');
// prettier-ignore
const ProdDetails = () => import('@/entities/prod/prod-details.vue');
// prettier-ignore
const Cust = () => import('@/entities/cust/cust.vue');
// prettier-ignore
const CustUpdate = () => import('@/entities/cust/cust-update.vue');
// prettier-ignore
const CustDetails = () => import('@/entities/cust/cust-details.vue');
// prettier-ignore
const Motor = () => import('@/entities/motor/motor.vue');
// prettier-ignore
const MotorUpdate = () => import('@/entities/motor/motor-update.vue');
// prettier-ignore
const MotorDetails = () => import('@/entities/motor/motor-details.vue');
// prettier-ignore
const CodeStatus = () => import('@/entities/code-status/code-status.vue');
// prettier-ignore
const CodeStatusUpdate = () => import('@/entities/code-status/code-status-update.vue');
// prettier-ignore
const CodeStatusDetails = () => import('@/entities/code-status/code-status-details.vue');
// prettier-ignore
const SandenStockCountVw = () => import('@/entities/sanden-stock-count-vw/sanden-stock-count-vw.vue');
// prettier-ignore
const SandenStockCountVwDetails = () => import('@/entities/sanden-stock-count-vw/sanden-stock-count-vw-details.vue');
// prettier-ignore
const CustStockCountVw = () => import('@/entities/cust-stock-count-vw/cust-stock-count-vw.vue');
// prettier-ignore
const CustStockCountVwDetails = () => import('@/entities/cust-stock-count-vw/cust-stock-count-vw-details.vue');
// prettier-ignore
const CustType = () => import('@/entities/cust-type/cust-type.vue');
// prettier-ignore
const CustTypeUpdate = () => import('@/entities/cust-type/cust-type-update.vue');
// prettier-ignore
const CustTypeDetails = () => import('@/entities/cust-type/cust-type-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'captcha',
      name: 'Captcha',
      component: Captcha,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'captcha/new',
      name: 'CaptchaCreate',
      component: CaptchaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'captcha/:captchaId/edit',
      name: 'CaptchaEdit',
      component: CaptchaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'captcha/:captchaId/view',
      name: 'CaptchaView',
      component: CaptchaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-req',
      name: 'EcpayReq',
      component: EcpayReq,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-req/new',
      name: 'EcpayReqCreate',
      component: EcpayReqUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-req/:ecpayReqId/edit',
      name: 'EcpayReqEdit',
      component: EcpayReqUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-req/:ecpayReqId/view',
      name: 'EcpayReqView',
      component: EcpayReqDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-resp',
      name: 'EcpayResp',
      component: EcpayResp,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-resp/new',
      name: 'EcpayRespCreate',
      component: EcpayRespUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-resp/:ecpayRespId/edit',
      name: 'EcpayRespEdit',
      component: EcpayRespUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'ecpay-resp/:ecpayRespId/view',
      name: 'EcpayRespView',
      component: EcpayRespDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'param',
      name: 'Param',
      component: Param,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'param/new',
      name: 'ParamCreate',
      component: ParamUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'param/:paramId/edit',
      name: 'ParamEdit',
      component: ParamUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'param/:paramId/view',
      name: 'ParamView',
      component: ParamDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'log-aop',
      name: 'LogAop',
      component: LogAop,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'log-aop/new',
      name: 'LogAopCreate',
      component: LogAopUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'log-aop/:logAopId/edit',
      name: 'LogAopEdit',
      component: LogAopUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'log-aop/:logAopId/view',
      name: 'LogAopView',
      component: LogAopDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product',
      name: 'Product',
      component: Product,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-image',
      name: 'ProductImage',
      component: ProductImage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-image/new',
      name: 'ProductImageCreate',
      component: ProductImageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-image/:productImageId/edit',
      name: 'ProductImageEdit',
      component: ProductImageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-image/:productImageId/view',
      name: 'ProductImageView',
      component: ProductImageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'status',
      name: 'Status',
      component: Status,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'status/new',
      name: 'StatusCreate',
      component: StatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'status/:statusId/edit',
      name: 'StatusEdit',
      component: StatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'status/:statusId/view',
      name: 'StatusView',
      component: StatusDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stock',
      name: 'Stock',
      component: Stock,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stock/new',
      name: 'StockCreate',
      component: StockUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stock/:stockId/edit',
      name: 'StockEdit',
      component: StockUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'stock/:stockId/view',
      name: 'StockView',
      component: StockDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'record',
      name: 'Record',
      component: Record,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'record/new',
      name: 'RecordCreate',
      component: RecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'record/:recordId/edit',
      name: 'RecordEdit',
      component: RecordUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'record/:recordId/view',
      name: 'RecordView',
      component: RecordDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'prod',
      name: 'Prod',
      component: Prod,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'prod/new',
      name: 'ProdCreate',
      component: ProdUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'prod/:prodId/edit',
      name: 'ProdEdit',
      component: ProdUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'prod/:prodId/view',
      name: 'ProdView',
      component: ProdDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust',
      name: 'Cust',
      component: Cust,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust/new',
      name: 'CustCreate',
      component: CustUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust/:custId/edit',
      name: 'CustEdit',
      component: CustUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust/:custId/view',
      name: 'CustView',
      component: CustDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'motor',
      name: 'Motor',
      component: Motor,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'motor/new',
      name: 'MotorCreate',
      component: MotorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'motor/:motorId/edit',
      name: 'MotorEdit',
      component: MotorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'motor/:motorId/view',
      name: 'MotorView',
      component: MotorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-status',
      name: 'CodeStatus',
      component: CodeStatus,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-status/new',
      name: 'CodeStatusCreate',
      component: CodeStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-status/:codeStatusId/edit',
      name: 'CodeStatusEdit',
      component: CodeStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'code-status/:codeStatusId/view',
      name: 'CodeStatusView',
      component: CodeStatusDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/sanden-stock-count-vw',
      name: 'SandenStockCountVw',
      component: SandenStockCountVw,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/sanden-stock-count-vw/:sandenStockCountVwId/view',
      name: 'SandenStockCountVwView',
      component: SandenStockCountVwDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/cust-stock-count-vw',
      name: 'CustStockCountVw',
      component: CustStockCountVw,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/cust-stock-count-vw/:custStockCountVwId/view',
      name: 'CustStockCountVwView',
      component: CustStockCountVwDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust-type',
      name: 'CustType',
      component: CustType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust-type/new',
      name: 'CustTypeCreate',
      component: CustTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust-type/:custTypeId/edit',
      name: 'CustTypeEdit',
      component: CustTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cust-type/:custTypeId/view',
      name: 'CustTypeView',
      component: CustTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
