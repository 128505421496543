var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        staticClass: "app-header",
      },
      [_c("ribbon"), _vm._v(" "), _c("jhi-navbar")],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "background" }),
    _vm._v(" "),
    _c("div", {
      staticClass: "logo",
      on: {
        click: function ($event) {
          return _vm.toHome()
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "jh-card" },
        [_c("router-view"), _vm._v(" "), _c("br")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }