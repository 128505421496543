import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd151',
    name: 'Sd151',
    component: () => import('@/sandentw/sd150/sd151.vue'),
  },
  {
    path: '/sd152',
    name: 'Sd152',
    component: () => import('@/sandentw/sd150/sd152.vue'),
  },
  {
    path: '/sd153/:id/edit',
    name: 'Sd153Edit',
    component: () => import('@/sandentw/sd150/sd153.vue'),
  },
];
