import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

// const Home = () => import('@/core/home/home.vue');
const Home = () => import('@/sandentw/sd100/sd101.vue');
const Error = () => import('@/core/error/error.vue');
import account from '@/router/account';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
import wccpayPages from '@/wccpay/pages';
import sd100Pages from '@/sandentw/sd100/pages';
import sd110Pages from '@/sandentw/sd110/pages';
import sd120Pages from '@/sandentw/sd120/pages';
import sd130Pages from '@/sandentw/sd130/pages';
import sd140Pages from '@/sandentw/sd140/pages';
import sd150Pages from '@/sandentw/sd150/pages';
import sd160Pages from '@/sandentw/sd160/pages';
import sd170Pages from '@/sandentw/sd170/pages';
import sd180Pages from '@/sandentw/sd180/pages';
import sd190Pages from '@/sandentw/sd190/pages';
import sd200Pages from '@/sandentw/sd200/pages';

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    ...account,
    ...admin,
    entities,
    ...pages,
    ...wccpayPages,
    ...sd100Pages,
    ...sd110Pages,
    ...sd120Pages,
    ...sd130Pages,
    ...sd140Pages,
    ...sd150Pages,
    ...sd160Pages,
    ...sd170Pages,
    ...sd180Pages,
    ...sd190Pages,
    ...sd200Pages,
  ]
});

export default router;
