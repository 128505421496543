import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/wcc101',
    name: 'Wcc101',
    component: () => import('@/wccpay/wcc100/wcc101.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/wcc102',
    name: 'Wcc102',
    component: () => import('@/wccpay/wcc100/wcc102.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/wcc301',
    name: 'Wcc301',
    component: () => import('@/wccpay/wcc300/wcc301.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/wcc302/:productId/view',
    name: 'Wcc302View',
    component: () => import('@/wccpay/wcc300/wcc302.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/wcc401',
    name: 'Wcc401',
    component: () => import('@/wccpay/wcc400/wcc401.vue'),
    meta: { authorities: [Authority.USER] },
  },
  {
    path: '/wcc501',
    name: 'Wcc501',
    component: () => import('@/wccpay/wcc500/wcc501.vue'),
    meta: { authorities: [Authority.USER] },
  },
]
