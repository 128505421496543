import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sd141',
    name: 'Sd141',
    component: () => import('@/sandentw/sd140/sd141.vue'),
  },
  {
    path: '/sd142',
    name: 'Sd142',
    component: () => import('@/sandentw/sd140/sd142.vue'),
  },
  {
    path: '/sd143/:id/edit',
    name: 'Sd143Edit',
    component: () => import('@/sandentw/sd140/sd143.vue'),
  },
  {
    path: '/sd144',
    name: 'Sd144',
    component: () => import('@/sandentw/sd140/sd144.vue'),
  },
];
