var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "justify-content-center" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "8", xl: "4" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            show: _vm.dismissCountDown,
                            dismissible: "",
                            "data-cy": "loginError",
                            variant: "danger",
                          },
                          on: {
                            dismissed: function ($event) {
                              _vm.dismissCountDown = 0
                            },
                            "dismiss-count-down": _vm.countDownChanged,
                          },
                        },
                        [
                          _c(
                            "h4",
                            {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("login.messages.error.authentication")
                                ),
                              },
                            },
                            [
                              _c("strong", [_vm._v("Failed to sign in!")]),
                              _vm._v(
                                " Please check your credentials and try again.\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form",
                        {
                          attrs: { role: "form" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.doLogin()
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "h4",
                                {
                                  staticStyle: { color: "#eb6864" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.form['username.label']")
                                    ),
                                  },
                                },
                                [_vm._v("username")]
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "username",
                                  type: "text",
                                  name: "username",
                                  placeholder: _vm.$t(
                                    "global.form['username.placeholder']"
                                  ),
                                  "data-cy": "username",
                                  size: "lg",
                                },
                                model: {
                                  value: _vm.login,
                                  callback: function ($$v) {
                                    _vm.login = $$v
                                  },
                                  expression: "login",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "h4",
                                {
                                  staticStyle: { color: "#eb6864" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("login.form.password")
                                    ),
                                  },
                                },
                                [_vm._v("password")]
                              ),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: "password",
                                  type: "password",
                                  name: "password",
                                  placeholder: _vm.$t(
                                    "login.form['password.placeholder']"
                                  ),
                                  "data-cy": "password",
                                  size: "lg",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("b-form-group", [
                            _c(
                              "h4",
                              {
                                staticStyle: { color: "#eb6864" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.form['captchaCode.label']")
                                  ),
                                },
                              },
                              [_vm._v("captchaCode")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-8" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "captchaCode",
                                      type: "text",
                                      name: "captchaCode",
                                      placeholder: _vm.$t(
                                        "global.form['captchaCode.placeholder']"
                                      ),
                                      "data-cy": "captchaCode",
                                      size: "lg",
                                    },
                                    model: {
                                      value: _vm.captchaCode,
                                      callback: function ($$v) {
                                        _vm.captchaCode = $$v
                                      },
                                      expression: "captchaCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.captcha
                                ? _c("div", { staticClass: "col-4" }, [
                                    _c("img", {
                                      staticStyle: {
                                        "max-width": "100%",
                                        height: "48px",
                                      },
                                      attrs: {
                                        src:
                                          "data:" +
                                          _vm.captcha.imageContentType +
                                          ";base64," +
                                          _vm.captcha.image,
                                        alt: "captcha image",
                                      },
                                    }),
                                  ])
                                : _c(
                                    "div",
                                    { staticClass: "col-4" },
                                    [
                                      _c("b-spinner", {
                                        attrs: {
                                          variant: "primary",
                                          label: "Loading...",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "rememberMe",
                                name: "rememberMe",
                                checked: "",
                                size: "lg",
                              },
                              model: {
                                value: _vm.rememberMe,
                                callback: function ($$v) {
                                  _vm.rememberMe = $$v
                                },
                                expression: "rememberMe",
                              },
                            },
                            [
                              _c("span", [
                                _c(
                                  "h4",
                                  {
                                    staticStyle: { color: "#eb6864" },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("login.form.rememberme")
                                      ),
                                    },
                                  },
                                  [_vm._v("Remember me")]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                block: "",
                                "data-cy": "submit",
                                type: "submit",
                                variant: "primary",
                                disabled:
                                  _vm.captcha === null || _vm.loadingButton,
                                size: "lg",
                              },
                            },
                            [
                              _c(
                                "h4",
                                [
                                  _vm.loadingButton
                                    ? _c("b-spinner", {
                                        attrs: { label: "Loading..." },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("login.form.button")
                                        ),
                                      },
                                    },
                                    [_vm._v("Sign in")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "justify-content-center" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "8", xl: "4" } },
            [
              _c("hr"),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: {
                    block: "",
                    variant: "outline-primary",
                    size: "lg",
                    to: "/",
                  },
                },
                [_c("h4", [_vm._v("回首頁")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }