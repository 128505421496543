var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "bg-primary",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [
          _c("span", { staticClass: "logo-img" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-title" }, [
            _c(
              "h4",
              { domProps: { textContent: _vm._s(_vm.$t("global.title")) } },
              [_vm._v("WccPay")]
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-version" }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }