import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/sd101',
    name: 'Sd101',
    component: () => import('@/sandentw/sd100/sd101.vue'),
  },
  {
    path: '/sd102',
    name: 'Sd102',
    component: () => import('@/sandentw/sd100/sd102.vue'),
  },
]
